// setup
@import './_mixins/breakpoints';
@import './_mixins/font-size';
@import './_variables/colors';
@import './_variables/fonts';
@import './_variables/breakpoints';
// layout
@import "./_layout/loading";
@import "./_layout/header-top";
// component
@import "./_component/_top/fv";
@import "./_component/_top/section1.scss";
@import "./_component/_top/section2.scss";
@import "./_component/_top/section3.scss";
@import "./_component/_top/section4.scss";
// for display animation style
#top {
    position: relative;
}

// style component
// section title
.title {
    margin-bottom: 16px;

    @include bp(lg) {
        margin-bottom: unset;
    }

    h2 {
        font-weight: 700;
        font-family: $font-en;
        font-size: 3.2rem;

        @include bp(lg) {
            font-size: 6rem;
        }
    }

    p {
        font-weight: 500;
        font-size: 1.4rem;

        @include bp(lg) {
            font-size: 2.2rem;
        }
    }
}

// link button
.link-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    font-weight: 500;
    border: solid 1px $black;
    background-color: $white;
    
    @include bp(lg) {
        height: 80px;
        font-size: 2.4rem;
        transition: all 300ms;

        &:hover {
            background-color: $blue-1;
            color: $white;
            border-color: $blue-1;
    
            .arrow {
                fill: #fff;
            }
        }
    }

    .arrow {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        width: 6px;
        height: auto;

        @include bp(lg) {
            right: 20px;
            width: 8px;
        }
    }
}