.loading-display {
    position: fixed;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    width: 100vw;
    animation-duration: 1000ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-direction: normal;
    animation-fill-mode: forwards;
    

    &__logo {
        opacity: 0;
        width: 136px;
        height: auto;
        fill: #003A78;
        animation-name: fadeIn;
        animation-duration: 1200ms;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
        animation-direction: normal;
        animation-fill-mode: forwards;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1.0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1.0;
    }
    100% {
        opacity: 0;
    }
}