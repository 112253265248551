.section-4 {
    display: none;
    margin-bottom: 80px;

    @include bp(lg) {
        display: block;
    }

    &__main {
        display: flex;
        width: 100%;
        height: auto;
    }

    &__main .content-1 {
        max-width: 284px;
        width: 28.4%;

        .title {
            margin-bottom: 60px;
        }

        .link-button {
            height: 64px;
            font-size: 1.6rem;
        }
    }

    &__main .content-2 {
        margin-left: auto;
        width: 60%;
        height: auto;

        .news-list {
            width: 100%;
            height: auto;
            border-top: solid 1px $gray-3;
            border-bottom: solid 1px $gray-3;

            .no-article {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 240px;
                font-size: 1.8rem;
                font-weight: 200;
            }

            .news {
                position: relative;
                padding: 24px 0;
                width: 100%;
                height: auto;
                border-bottom: solid 1px $gray-3;

                &:last-child {
                    border-bottom: unset;
                }

                a {
                    display: block;
                    width: 100%;
                    height: auto;

                    &:hover {
                        color: $blue-1;

                        time {
                            color: $blue-1;
                        }

                        & + .arrow {
                            fill: $blue-1;
                        }
                    }
                }

                time {
                    color: $gray-3;
                    font-family: $font-en;
                    font-size: 1.4rem;
                    font-weight: 700;
                    transition: all 300ms;
                }

                .news-title {
                    margin-top: 8px;
                    line-height: 1.25;
                    max-width: 544px;
                    width: 91%;
                    transition: all 300ms;
                }

                .arrow {
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    transform: translateY(-50%);
                    transition: all 300ms;
                    width: 8px;
                    height: auto;
                }
            }
        }
    }
}

.section-4-sp {
    background-color: $blue-3;
    padding: 40px 0;

    @include bp(lg) {
        display: none;
    }

    &__news-list {
        margin-bottom: 16px;
        padding: 16px 0;
        border-top: solid 1px $gray-3;
        border-bottom: solid 1px $gray-3;
        min-height: auto;

        .no-article {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 180px;
            font-size: 1.8rem;
            font-weight: 200;
        }

        li {
            position: relative;
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom: solid 1px $gray-3;
            width: 100%;
            height: auto;

            &:last-child {
                margin-bottom: unset;
                padding-bottom: unset;
                border-bottom: none;
            }

            a {
                display: flex;
                flex-direction: column;
                width: 84.4%;
                height: auto;
            }

            time {
                margin-bottom: 8px;
                font-size: 1.4rem;
                font-weight: 700;
                font-family: $font-en;
                color: $gray-3;
            }

            .news-title {
                font-size: 1.4rem;
                line-height: 1.42;
            }

            .arrow {
                position: absolute;
                top: 50%;
                right: 8px;
                transform: translateY(-50%);
                width: 6px;
                height: auto;
            }
        }
    }
}