.section-2 {
    display: none;
    margin-bottom: 232px;

    @include bp(lg) {
        display: block;
    }

    &__main {
        display: flex;
        width: 100%;
        height: auto;
    }

    &__main .content-1 {
        z-index: 1;
        display: grid;
        margin-top: auto;
        max-width: 568px;
        width: 57%;
        height: auto;

        &__img {
            max-width: 280px;
            width: 100%;
            height: auto;

            &:nth-child(1) {
                grid-row: 1;
                grid-column: 1;
                margin-bottom: calc(200% - 560px);
            }

            &:nth-child(2) {
                grid-row: 1;
                grid-column: 2;
                margin-left: auto;
                margin-bottom: calc(200% - 560px);
            }

            &:nth-child(3) {
                grid-row: 2;
                grid-column: 1;
            }

            &:nth-child(4) {
                grid-row: 2;
                grid-column: 2;
                margin-left: auto;
            }

            img {
                width: 100%;
            }
        }
    }

    &__main .content-2 {
        position: relative;
        z-index: 0;
        margin-left: auto;
        padding-top: 13px;
        max-width: 360px;
        width: 36%;

        .title {
            margin-bottom: 176px;
        }

        .text-1 {
            margin-bottom: 24px;
            font-size: 1.6rem;
            line-height: 1.75;
        }

        .background-triangle {
            position: absolute;
            z-index: -1;
            top: 0;
            left: -38.8%;
            clip-path: polygon(0 0, 0% 100%, 100% 0);
            background-color: $blue-3;
            max-width: 400px;
            width: 112%;
            aspect-ratio: 1/1;
        }
    }
}

.section-2-sp {
    @include bp(lg) {
        display: none;
    }

    &__img {
        display: grid;
        margin-bottom: 16px;
        width: 100%;
        height: auto;

        .img-wrapper {
            width: 98.4%;
            height: auto;

            &:nth-child(1) {
                grid-row: 1;
                grid-column: 1;

                img {
                    padding-bottom: calc(100vw - (100% * 2));
                }
            }

            &:nth-child(2) {
                grid-row: 1;
                grid-column: 2;
                margin-left: auto;
                
                img {
                    padding-bottom: calc(100vw - (100% * 2));
                }
            }

            &:nth-child(3) {
                grid-row: 2;
                grid-column: 1;
            }

            &:nth-child(4) {
                grid-row: 2;
                grid-column: 2;
                margin-left: auto;
            }

            img {
                width: 100%;
            }
        }
    }

    .text-1 {
        margin-bottom: 16px;
        line-height: 1.5;
        text-align: justify;
    }
}