.section-3 {
    display: none;
    margin-bottom: 76px;

    @include bp(lg) {
        display: block;
    }

    &__main {
        position: relative;
        display: flex;
        padding-top: 22px;
        padding-bottom: 88px;
        width: 100%;
    }

    &__main .content-1 {
        position: relative;
        z-index: 1;
        max-width: 360px;
        width: 36%;
        height: auto;

        .title {
            margin-bottom: 88px;
        }

        .text-1 {
            position: relative;
            z-index: 1;
            margin-bottom: 20px;
            padding-top: 8px;
            padding-right: 40px;
            padding-bottom: 8px;
            background-color: $white;
            width: fit-content;
            height: auto;
            font-size: 3.6rem;
            line-height: 1.5;
            font-weight: 700;
            white-space: nowrap;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                transform: translateX(-100%);
                display: block;
                background-color: $white;
                width: calc((100vw - 1000px) / 2);
                height: 100%;
            }
        }
    }

    &__main .content-2 {
        position: relative;
        z-index: 0;
        margin-top: auto;
        margin-left: auto;
        width: 60%;
        height: auto;

        &__img {
            max-width: 600px;
            width: 100%;
            height: auto;

            img {
                width: 100%;
            }
        }
    }

    &__main .background-trapezoid {
        position: absolute;
        z-index: -1;
        top: 0;
        left: -19%;
        transform: skewX(-45deg);
        max-width: 565px;
        width: 56.5%;
        height: 100%;
        background-color: $blue-3;
    }
}

.section-3-sp {
    @include bp(lg) {
        display: none;
    }

    &__img {
        margin-bottom: 16px;
        width: 100%;
        height: auto;

        img {
            width: 100%;
        }
    }

    .text-1 {
        margin-bottom: 16px;
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.55;
    }
}