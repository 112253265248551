.section-1 {
    display: none;
    padding-bottom: 80px;
    margin-bottom: 96px;
    position: relative;

    @include bp(lg) {
        display: block;
    }

    .content-1 {
        display: flex;
        width: 100%;
        height: auto;
    }

    .service-link {
        position: relative;
        z-index: 2;
        width: 360px;
        height: auto;
        margin-top: auto;

        .text-1 {
            width: fit-content;
            font-size: 3.6rem;
            white-space: nowrap;
            font-weight: 700;
            background-color: $white;
            padding: 8px 40px 8px 0;
            margin-bottom: 20px;
            line-height: 1.5;
        }
    }

    .img-wrapper {
        max-width: 600px;
        width: 60%;
        height: auto;
        position: relative;
        z-index: 1;
        margin-top: -32px;
        margin-left: auto;

        img {
            width: 100%;
        }
    }

    .background-trapezoid {
        position: absolute;
        right: 0;
        bottom: 0;
        clip-path: polygon(33% 0, 100% 0, 100% 100%, 0% 100%);
        background-color: $blue-1;
        width: calc(((100vw - 1000px) / 2) + 600px);
        height: 349px;
    }
}

.section-1-sp {
    @include bp(lg) {
        display: none;
    }
    
    &__img {
        margin-bottom: 16px;
        width: 100%;
        height: auto;

        img {
            width: 100%;
        }
    }

    .text-1 {
        margin-bottom: 16px;
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 1.55;
    }
}