.fv-wrapper {
    overflow-x: hidden;
}

.fv-pc {
    position: relative;
    display: none;
    margin-bottom: 250px;
    background: linear-gradient(180deg, $blue-1 0 90%, $white 90% 100%);
    width: 100%;
    height: calc(100vh - 80px);
    background-color: $blue-1;

    @include bp(lg) {
        display: block;
    }

    &__logo-area {
        position: absolute;
        z-index: 1;
        top: 25.2%;
        left: 50%;
        opacity: 0;
        transform: translateX(-50%) translateY(100px);
        transform: translateX(-50%);
        width: fit-content;
        height: auto;
        animation-duration: 350ms;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
        animation-direction: normal;
        animation-fill-mode: forwards;
    
        &--logo {
            max-width: 226px;
            width: 100%;
            height: auto;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 20px;
    
            img {
                width: 100%;
                height: auto;
            }
        }
    
        h1 {
            @include font-vw(74, 1920);
            text-align: center;
            font-weight: 500;
            line-height: 1.25;
            color: $white;
        }
    }
}

.fv-img {
    position: absolute;
    z-index: 1;

    img {
        width: 100%;
        height: auto;
    }

    &.img1 {
        max-width: 429px;
        width: 21.8%;
        height: auto;
        top: 9.8%;
        left: 140px;
    }

    &.img2 {
        max-width: 705px;
        width: 36.7%;
        height: auto;
        top: 59.5%;
        left: 0;
    }

    &.img3 {
        max-width: 426px;
        width: 22.2%;
        height: auto;
        top: 7.2%;
        right: 166px;
    }

    &.img4 {
        max-width: 360px;
        width: 18.7%;
        height: auto;
        top: 25.2%;
        right: 0;
        z-index: 2;
    }

    &.img5 {
        max-width: 546px;
        width: 28.4%;
        height: auto;
        top: 69.5%;
        right: 16.6%;

        &::after {
            content: '';
            width: 120%;
            height: 170%;
            position: absolute;
            z-index: -1;
            left: 45%;
            bottom: 0;
            background-color: $blue-3;
            transform: skewX(-45deg);
            overflow-x: hidden;
        }
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(-50%) translateY(100px);
        opacity: 0;
    }
    100% {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
    }
}

.fv-sp {
    position: relative;
    width: 100%;
    height: auto;

    @include bp(lg) {
        display: none;
    }

    &__img {
        width: 100%;
        height: auto;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__block {
        background-color: $blue-1;
        width: 100%;
        height: 60px;
    }

    &__triangle {
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        background-color: $blue-3;
        min-width: 316px;
        width: 84.2%;
        aspect-ratio: 1 / 1;
    }

    &__logo {
        position: absolute;
        z-index: 1;
        right: 16px;
        bottom: 16px;
        width: 53.5%;

        svg {
            margin-bottom: 16px;
            fill: #003A78;
            width: 100%;
            height: auto;
        }

        p {
            margin-left: auto;
            line-height: 1.4;
            font-weight: 500;
            width: fit-content;
            @include font-vw(20, 375);
        }
    }
}