// index用のヘッダー
.header-top {
    display: none;
    align-items: center;
    position: relative;
    background-color: $white;
    width: 100%;
    height: 80px;

    @include bp(lg) {
        display: flex;
    }

    * {
        transition: all 300ms;
    }

    *::before,
    *::after {
        transition: all 300ms;
    }

    .logo {
        position: relative;
        margin-left: 33px;
        max-width: 230px;
        height: auto;

        .triangle {
            background-color: $blue-3;
            display: block;
            position: absolute;
            z-index: 1;
            clip-path: polygon(0 0, 0 100%, 100% 0);
            top: -8px;
            left: -13px;
            width: 360px;
            aspect-ratio: 1 / 1;
        }

        img {
            width: 100%;
            height: auto;
            position: relative;
            z-index: 2;
        }
    }

    .nav {
        position: relative;
        z-index: 1;
        display: flex;
        margin-left: auto;
        width: auto;
        height: 100%;

        li {
            margin-left: 24px;
            align-self: center;
        }

        .item > a {
            font-weight: 400;

            &:hover {
                color: $blue-1;
            }
        }

        .contact {
            width: 170px;
            height: 100%;
            background-color: $gray-1;
            margin-right: 50px;
            position: relative;

            &::after {
                content: '';
                width: 30px;
                height: 100%;
                display: block;
                position: absolute;
                background-color: $gray-1;
                top: 0;
                right: -30px;
                clip-path: polygon(0 0, 0% 100%, 100% 50%);
            }

            &:hover {
                background-color: $blue-1;

                &::after {
                    background-color: $blue-1;
                }
            }
        }

        .contact > a {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            font-family: $font-jp;
            color: #fff;
            transition: none;

            .icon-wrapper {
                width: 20px;
                height: auto;
                margin-right: 8px;

                img {
                    width: 100%;
                }
            }
        }
    }
}